export const HeroStyles = (heroBackground: string, styles: any) => { 
    return ({ theme }: any) => ({
        alignSelf: "center",
        width: "100%",
        height: 495,
        borderRadius: theme.shape.borderRadius,
        outline: "1px solid",
        backgroundSize: "contain",
        boxShadow: "0 0 24px 12px hsla(210, 100%, 25%, 0.2)",
        backgroundImage: `url(${heroBackground})`,
        outlineColor: "hsla(210, 100%, 80%, 0.1)",
        ...styles
    });
};
  
export const HeroBoxStyles = (theme: any) => ({
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundImage:
        "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)",
    ...theme.applyStyles("dark", {
        backgroundImage:
        "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)",
    }),
});