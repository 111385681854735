export const SubstackSubscribe = () => {
  return (
    <iframe
      src="https://azulejoenergy.substack.com/embed"
      width="480"
      height="320"
      style={{ background: "none" }}
      frameBorder="0"
      scrolling="no"
    ></iframe>
  );
};
