import { modeModel } from "../../model/ModeModel";
export interface ChipConditionalStylesProps {
    selected?: boolean;
}

export const ChipConditionalStyles = ({ theme }: any) => {

    const { mode } = modeModel;
  
    return {
      // variants is an array that contains objects defining how the component should be styled based on certain props or conditions.
      variants: [
        {
          // props: A function that returns a boolean indicating whether this variant should be applied. In this case, it checks if the selected prop is true.
          props: ({ selected }: ChipConditionalStylesProps) => selected,
          style: {
            background:
              "linear-gradient(to bottom right, hsl(210, 98%, 48%), hsl(210, 98%, 35%))",
            color: "hsl(0, 0%, 100%)",
            borderColor: theme.palette.primary.light,
            "& .MuiChip-label": {
              color: "hsl(0, 0%, 100%)",
            },
            ...theme.applyStyles(mode, {
              borderColor: theme.palette.primary.dark,
            }),
          },
        },
      ],
  };
};