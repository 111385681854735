const INDEFINETLY: number = 20290101;

export const eventsData = [
    {
      id: "1",
      title: "Synchronize Photos with Azul Cloud",
      start: "2024-09-16 13:30",
      end: "2024-09-16 15:10",
      rrule: `FREQ=DAILY;UNTIL=${INDEFINETLY}`,
    },
];