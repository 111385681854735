import OutlinedInput from "@mui/material/OutlinedInput";
import { useState } from "react";

export const OutlinedTextInput = (props: any) => {
  const [inputText, setInputText] = useState<string>(props.text);
  const callback: (newText: string) => any = props.callback;
  const width: string =
    1.5 * (props.width ? props.width : props.text.length) + "ch";
  const sx: any = props.sx;
  const placeholder: string = props.placeholder
    ? props.placeholder
    : "Please enter text";

  return (
    <OutlinedInput
      onChange={(event: any) => {
        setInputText(event.target.value);
      }}
      onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
          callback(inputText);
        }
      }}
      value={inputText}
      placeholder={placeholder}
      sx={{
        "& .MuiInputBase-input": {
          textAlign: "center",
        },
        width: width,
        ...sx,
      }}
    />
  );
};
