import maplibregl from "maplibre-gl";
import { MapLibreGLUtils } from "./MapLibreGLUtils";

export class MapLibreGLPoints {
    private map: maplibregl.Map;
    static MIN_ZOOM: number = 0;
    static MAX_ZOOM: number = 22;

    constructor(map: maplibregl.Map) {
        this.map = map;
    }

    deletePointLayer(sourceName: string) {
      const layerName: string = MapLibreGLUtils.getLayerName(sourceName);
      if (this.map.getLayer(layerName)) {
        this.map.removeLayer(layerName);
      }
    }

    addPointLayer(
        sourceName: string,
        layerName: string,
        sourceColor: string,
        minZoom: number = MapLibreGLPoints.MIN_ZOOM,
        maxZoom: number = MapLibreGLPoints.MAX_ZOOM,
    ) {      
        this.map.addLayer({
          id: layerName,
          type: "circle",
          source: sourceName,
          minzoom: minZoom,
          maxzoom: maxZoom,
          paint: {
            "circle-radius": 6,
            "circle-color": sourceColor,
          },
        });
      
        this.map.on("click", layerName, (e: any) => {
          const coordinates = e.features[0].geometry.coordinates.slice();
          const { title, description } = e.features[0].properties;
      
          new maplibregl.Popup()
            .setLngLat(coordinates)
            .setHTML(
              `<strong style="color: black;">${title}</strong><p style="color: black;">${description}</p>`
            )
            .addTo(this.map);
        });
      
        this.map.on("mouseenter", layerName, () => {
          this.map.getCanvas().style.cursor = "pointer";
        });
      
        this.map.on("mouseleave", layerName, () => {
          this.map.getCanvas().style.cursor = "";
        });
    }
}