import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { PublicImage } from "../img/PublicImage";

const Copyright = () => {
  return (
    <Typography variant="body2" sx={{ color: "text.secondary" }}>
      {"© "}
      <Link variant="body2" href="https://www.azulejo.io/">
        Azulejo Technologies, Inc.&nbsp;
      </Link>
      {`${new Date().getFullYear()}`}
    </Typography>
  );
};

const Separator = () => {
  return (
    <Typography sx={{ display: "inline", mx: 0.5, opacity: 0.5 }}>
      &nbsp;•&nbsp;
    </Typography>
  );
};

const Spaces = () => {
  return <>&nbsp;&nbsp;</>;
};

export const Footer = (props: any) => {
  const children: any = props.children;

  return (
    <>
      {/* padding to keep page content from going behind the footer */}
      <Box height="20px" />
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: "50%",
          transform: "translateX(-50%)",
          backgroundColor: "background.default",
          display: "flex",
          padding: { xs: 1 },
          width: "100%",
          borderTop: "1px solid",
          borderColor: "divider",
        }}
      >
        <Box sx={{ display: "flex", opacity: "70%", alignItems: "center" }}>
          <PublicImage filename="logo_32x32.png" width={20} height={20} />
          <Spaces />
          <Copyright />
          {/* <Separator />
          <Link color="text.secondary" variant="body2" href="#">
            Privacy
          </Link>
          <Separator />
          <Link color="text.secondary" variant="body2" href="#">
            Terms
          </Link> */}
          <Separator />
          {children}
        </Box>
      </Box>
    </>
  );
};
