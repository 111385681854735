import { Chip } from "@mui/material";
import { styled } from "@mui/material/styles";

import {
  ChipConditionalStyles,
  ChipConditionalStylesProps,
} from "../styles/ChipConditionalStyles";

export const FeatureChip = styled(Chip)<ChipConditionalStylesProps>(
  ChipConditionalStyles
);
