import { makeAutoObservable } from 'mobx';

class HamburgerMenuModel {
  isMenuOpen: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setIsMenuOpen = (isOpen: boolean): void => {
    this.isMenuOpen = isOpen;
  };
}

export const hamburgerMenuModel = new HamburgerMenuModel();
