
export interface GeoJSONPointFeature {
    type: string;
    geometry: { type: string; coordinates: number[] };
    properties: any;
}

export interface GeoJSONLineStringFeature {
    type: string;
    geometry: { type: string; coordinates: number[][] };
    properties: any;
}

export class GeoJSON {
    static createPoint(
        lng: number,
        lat: number,
        properties: any
    ): GeoJSONPointFeature {
        const feature: GeoJSONPointFeature = {
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: [lng, lat],
            },
            properties: properties,
        };
        return feature;
    }

    static createLineString(
        coordinates: number[][],
        properties: any
    ): GeoJSONLineStringFeature {
        const feature: GeoJSONLineStringFeature = {
            type: "Feature",
            geometry: {
                type: "LineString",
                coordinates: coordinates,
            },
            properties: properties,
        };
        return feature;
    }
      
    static create(features: GeoJSONPointFeature[] | GeoJSONLineStringFeature[]) {
        const geojsonData = {
            type: "FeatureCollection",
            features: features,
        };
        return geojsonData;
    }
}