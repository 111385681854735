import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { Box } from "@mui/material";
import Card from "@mui/material/Card";
import { observer } from "mobx-react";
import { useState } from "react";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import { TimelineEventDTO } from "../../model/TimelineDTO";
import { EditButton } from "../buttons/EditButton";
import { TrashButton } from "../buttons/TrashButton";
import { ImageGrid } from "../img/ImageGrid";
import { MultilineTextField } from "../input/MultilineTextField";
import { Modal } from "../modal/Modal";

export const TimelineEvent = observer((props: any) => {
  const setDescriptionCallback: any = props.setDescriptionCallback;
  const evnt: TimelineEventDTO = props.evnt;
  const index: number = props.index;
  const imageURLs: string[] = props.imageURLs;
  const imageS3URLs: string[] = props.imageS3URLs;
  const thumbURLs: string[] = props.thumbURLs;
  const timelineEventMediaURLCallback: any =
    props.timelineEventMediaURLCallback;
  const deleteTimelineEventCallback: any = props.deleteTimelineEventCallback;
  const showDates: boolean =
    props.showDates !== undefined ? props.showDates : true;
  const canEdit: boolean = props.canEdit !== undefined ? props.canEdit : true;
  const canDelete: boolean =
    props.canDelete !== undefined ? props.canDelete : true;
  const hasDescription: boolean =
    props.hasDescription !== undefined ? props.hasDescription : true;

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const borderColor: string = "hsla(220, 25%, 25%, .3)";

  return (
    <VerticalTimelineElement
      contentStyle={{
        boxShadow: "none",
        background: "none",
        border: `1px solid ${borderColor}`,
        borderRadius: "13px",
      }}
      contentArrowStyle={{
        borderRight: `10px solid ${borderColor}`,
      }}
      date={showDates ? `${evnt.from} - ${evnt.to}` : ""}
      iconStyle={{
        background: "rgb(33, 150, 243)",
        color: "#fff",
      }}
      icon={<PlayCircleIcon />}
      key={`timeline-${index}`}
    >
      <Box>
        {evnt.mediaURL && (
          <>
            {canEdit && (
              <EditButton
                clickCallback={() => setOpen(true)}
                sx={{ left: "20px" }}
              />
            )}
            {canDelete && (
              <TrashButton
                deleteCallback={() => deleteTimelineEventCallback()}
                sx={{ left: "50px" }}
              />
            )}
            <Card
              sx={{
                mb: 1,
                height: "300px",
                width: "100%",
                border: `1px solid ${borderColor}`,
                backgroundImage: `url(${evnt.mediaURL})`,
                backgroundSize: "cover",
                backgroundPosition: "top",
              }}
            />
          </>
        )}
        {hasDescription && (
          <MultilineTextField
            text={evnt.description}
            callback={(text: string) => setDescriptionCallback(text, index)}
            disabled={canEdit !== undefined ? !canEdit : false}
            sx={{
              opacity: 0.6,
              "& .MuiInputBase-input": {
                textAlign: "left",
              },
            }}
          />
        )}
      </Box>
      <Modal isOpen={open} closeCallback={handleClose}>
        <ImageGrid
          imageURLs={imageURLs}
          imageS3URLs={imageS3URLs}
          thumbURLs={thumbURLs}
          clickCallback={(url: string, s3URL: string) => {
            timelineEventMediaURLCallback(url, s3URL);
            setOpen(false);
          }}
        />
      </Modal>
    </VerticalTimelineElement>
  );
});
