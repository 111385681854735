import { createBrowserRouter } from "react-router-dom";

import { routes as omtRoutes } from "./omt/Routes";
import { routes as biDMRoutes } from "./bidm/Routes";
import { routes as landingRoutes } from "./landing/Routes";

const AppRouter = createBrowserRouter([
  ...omtRoutes,
  ...biDMRoutes,
  ...landingRoutes,
]);

export default AppRouter;
