
export class AuthPaths {
    static loginRoute(appName: string): string {
        return `/${appName}/app`;
    }

    static logoutRoute(appName: string = ""): string {
        return `/${appName}`;
    }

    static loginURL(appName: string): string {
        return `${window.location.origin}/${appName}/app`;
    }

    static logoutURL(appName: string): string {
        return `${window.location.origin}/${appName}`;
    }
}