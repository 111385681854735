import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import { observer } from "mobx-react";
import { hamburgerMenuModel } from "../../model/HamburgerMenuModel";
import { SandwichMenuButton } from "../buttons/SandwichMenuButton";

export const HamburgerMenu = observer((props: any) => {
  const children: any = props.children;
  const sx: any = props.sx;

  const { isMenuOpen, setIsMenuOpen } = hamburgerMenuModel;

  return (
    <>
      <SandwichMenuButton clickCallback={() => setIsMenuOpen(true)} sx={sx} />
      <Drawer
        anchor="top"
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
      >
        <Box sx={{ p: 2, backgroundColor: "background.default" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {/*<ToggleColorMode
              mode={mode}
              toggleColorMode={toggleColorMode}
            />*/}
          </Box>
          <Divider sx={{ my: 3 }} />
          {children}
        </Box>
      </Drawer>
    </>
  );
});
