import Box from "@mui/material/Box";
import { useTheme } from "@mui/system";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { PricingCard } from "../../cards/PricingCard";

export class Price {
  title: string;
  price: string;
  description: string[];
  buttonText: string;
  buttonVariant: string;
  subheader: string;

  constructor(
    title: string,
    price: string,
    description: string[],
    buttonText: string,
    buttonVariant: string,
    subheader: string = ""
  ) {
    this.title = title;
    this.price = price;
    this.description = description;
    this.buttonText = buttonText;
    this.buttonVariant = buttonVariant;
    this.subheader = subheader;
  }
}

export const Pricing = (props: any) => {
  const theme: any = useTheme();
  const appName: string = props.appName;
  const prices: Price[] = props.prices;

  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography
          component="h2"
          variant="h2"
          sx={{
            color: "text.primary",
            fontFamily: theme.typography.fontFamily,
          }}
        >
          Pricing
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        sx={{ alignItems: "center", justifyContent: "center" }}
      >
        {prices.map((tier, index: number) => (
          <PricingCard tier={tier} key={`pricing-${index}`} appName={appName} />
        ))}
      </Grid>
    </Container>
  );
};
