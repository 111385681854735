import Link from "@mui/material/Link";

export const StyledLink = (props: any) => {
  const name: string = props.name;
  const href: string = props.href;
  const sx: any = props.sx;

  return (
    <>
      <Link color="text.secondary" variant="body2" href={href} sx={sx}>
        {name}
      </Link>
    </>
  );
};
