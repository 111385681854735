import maplibregl from "maplibre-gl";
import { MapLibreGLUtils } from "./MapLibreGLUtils";

export class MapLibreGLHeatMaps {
    private map: maplibregl.Map;

    constructor(map: maplibregl.Map) {
        this.map = map;
    }

    addHeatLayer(
      sourceName: string,
    ) {
      const layerName: string = MapLibreGLUtils.getLayerName(sourceName);

      this.map.addLayer(
        {
          id: layerName,
            'type': 'heatmap',
            source: sourceName,
            'paint': {
                // Controls the influence of a feature on the heatmap.
                // Formula: ['interpolate', ['linear'], ['get', 'mag'], 0, 0, 6, 1]
                'heatmap-weight': [
                    'interpolate',
                    ['linear'],
                    ['get', 'mag'],
                    0, // If mag is 0, the weight is 0.
                    0,
                    1, // If mag is 1, the weight is 1.
                    1
                ],
                // Adjusts the overall intensity of the heatmap as a function of zoom level.
                // Formula: ['interpolate', ['linear'], ['zoom'], 0, 1, 9, 3]
                'heatmap-intensity': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    0,
                    1, // At zoom level 0, the intensity multiplier is 1.
                    9,
                    6 // At zoom level 9, the intensity multiplier is 6.
                ],
                // Specifies the color gradient for the heatmap based on density.
                // Formula: ['interpolate', ['linear'], ['heatmap-density'], ...]
                // Domain is 0 (low) to 1 (high).
                'heatmap-color': [
                    'interpolate',
                    ['linear'],
                    ['heatmap-density'], // A MapLibre-generated value representing the normalized density of points.
                    0,
                    'rgba(33,102,172,0)', // 0 maps to rgba(33,102,172,0) (transparent blue).
                    0.2,
                    'rgb(103,169,207)',
                    0.4,
                    'rgb(209,229,240)',
                    0.6,
                    'rgb(253,219,199)',
                    0.8,
                    'rgb(239,138,98)',
                    1,
                    'rgb(178,24,43)' // 1 maps to rgb(178,24,43) (deep red).
                ],
                // Controls the size of the heatmap "blobs" based on zoom level.
                // Formula: ['interpolate', ['linear'], ['zoom'], 0, 2, 9, 20]
                'heatmap-radius': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    0,  // At zoom level 0, the radius is 2 pixels.
                    2,
                    9,  // At zoom level 9, the radius is 20 pixels.
                    20, // Larger radii at higher zoom levels smooth the appearance of the heatmap.
                ],
                // Adjusts the opacity of the heatmap based on zoom level, facilitating a transition to another visualization (e.g., circle layer).
                // Formula: ['interpolate', ['linear'], ['zoom'], 7, 1, 9, 0]
                'heatmap-opacity': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    7, // At zoom level 7, the heatmap is fully opaque (1).
                    1,
                    9, // At zoom level 9, the heatmap becomes fully transparent (0).
                    0
                ]
            }
        },
      );
  }
}