import { observer } from "mobx-react";
import IconButton from "@mui/material/IconButton";
import WbSunnyRoundedIcon from "@mui/icons-material/WbSunnyRounded";
import ModeNightRoundedIcon from "@mui/icons-material/ModeNightRounded";

import { modeModel } from "../../model/ModeModel";

export const ThemeButton = observer((props: any) => {
  const { mode, toggleMode } = modeModel;

  return (
    <IconButton
      onClick={toggleMode}
      color="primary"
      aria-label="Theme toggle button"
      size="small"
      {...props}
    >
      {mode === "dark" ? (
        <WbSunnyRoundedIcon fontSize="small" />
      ) : (
        <ModeNightRoundedIcon fontSize="small" />
      )}
    </IconButton>
  );
});
