export const FeatureCardStyles = (theme: any) => ({
    p: 3,
    height: "fit-content",
    width: "100%",
    background: "none",
    "&:hover": {
      background:
        "linear-gradient(to bottom right, hsla(210, 100%, 97%, 0.5) 25%, hsla(210, 100%, 90%, 0.3) 100%)",
      borderColor: "primary.light",
      boxShadow: "0px 2px 8px hsla(0, 0%, 0%, 0.1)",
      ...theme.applyStyles("dark", {
        background:
          "linear-gradient(to right bottom, hsla(210, 100%, 12%, 0.2) 25%, hsla(210, 100%, 16%, 0.2) 100%)",
        borderColor: "primary.dark",
        boxShadow: "0px 1px 8px hsla(210, 100%, 25%, 0.5) ",
      }),
    },
  });

export const FeatureCardPlusStyles = ((theme: any) => ({
    backgroundColor: "action.selected",
    borderColor: "primary.light",
    ...theme.applyStyles("dark", {
      borderColor: "primary.dark",
    }),
  }));

export const FeatureCardBoxStyles = (theme: any) => ({
    color: "grey.400",
    ...theme.applyStyles("dark", {
      color: "grey.600",
    }),
  });