import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export const AddButton = (props: any) => {
  const sx = props.sx;
  const clickCallback: () => any = props.clickCallback;

  return (
    <Button
      onClick={clickCallback}
      size="large"
      sx={{
        ...sx,
        color: "inherit",
        p: 0,
        height: "300px",
        width: "100%",
        display: "flex",
        border: "1px solid",
        borderColor: "hsla(220, 25%, 25%, .8)",
        boxShadow: "none",
        justifyContent: "center",
      }}
    >
      <AddIcon />
    </Button>
  );
};
