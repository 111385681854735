import MenuItem from "@mui/material/MenuItem";
import { observer } from "mobx-react";
import { hamburgerMenuModel } from "../../model/HamburgerMenuModel";

export const HamburgerMenuItem = observer((props: any) => {
  const name: string = props.name;
  const onClick: any = props.onClick;

  const { setIsMenuOpen } = hamburgerMenuModel;

  return (
    <MenuItem
      onClick={() => {
        onClick();
        setIsMenuOpen(false);
      }}
    >
      {name}
    </MenuItem>
  );
});
