import Button from "@mui/material/Button";
import { AuthPaths } from "./AuthPaths";

export const Auth0LoginButton = (props: any) => {
  const buttonName: string = props.name;
  const sx = props.sx;
  const variant = props.variant;
  const appName = props.appName;

  return (
    <Button
      variant={variant}
      color="primary"
      size="small"
      sx={sx}
      href={AuthPaths.loginURL(appName)}
    >
      {buttonName}
    </Button>
  );
};
