import AddCircleIcon from "@mui/icons-material/AddCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { Button } from "@mui/material";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { useState } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import { TimelineDTO, TimelineEventDTO } from "../../model/TimelineDTO";
import { TimelineEvent } from "./TimelineEvent";
import { TimelineModal } from "./TimelineModal";

export const Timeline = observer((props: any) => {
  const timeline: TimelineDTO | null = props.timeline;
  const sortedTimelineEvents: TimelineEventDTO[] = props.sortedTimelineEvents;
  const addTimelineEventsCallback: (events: TimelineEventDTO[]) => any =
    props.addTimelineEventsCallback;
  const setDescriptionCallback: any = props.setDescriptionCallback;

  const imageURLs: string[] = props.imageURLs;
  const imageS3URLs: string[] = props.imageS3URLs;
  const thumbURLs: string[] = props.thumbURLs;
  const timelineEventMediaURLCallback: any =
    props.timelineEventMediaURLCallback;
  const deleteTimelineEventCallback: any = props.deleteTimelineEventCallback;

  const showAddButton: boolean =
    props.showAddButton !== undefined ? props.showAddButton : true;

  const showDates: boolean =
    props.showDates !== undefined ? props.showDates : true;

  const canEditEvent: boolean =
    props.canEditEvent !== undefined ? props.canEditEvent : true;
  const canDeleteEvent: boolean =
    props.canEditEvent !== undefined ? props.canEditEvent : true;
  const hasDescription: boolean =
    props.hasDescription !== undefined ? props.hasDescription : true;

  const [open, setOpen] = useState<boolean>(false);

  const addTimelineEvent: any = (evnt: TimelineEventDTO) => {
    let eventsCopy: TimelineEventDTO[] | undefined = toJS(timeline?.events);
    if (eventsCopy) {
      eventsCopy.push(evnt);
    } else {
      eventsCopy = [evnt];
    }
    addTimelineEventsCallback(eventsCopy);
  };

  return !timeline ? (
    <></>
  ) : (
    <>
      {showAddButton && (
        <Button onClick={() => setOpen(true)} sx={{ m: 0 }}>
          <AddCircleIcon />
        </Button>
      )}
      <VerticalTimeline>
        {sortedTimelineEvents.map((evnt: TimelineEventDTO, index: number) => (
          <TimelineEvent
            setDescriptionCallback={setDescriptionCallback}
            evnt={evnt}
            imageURLs={imageURLs}
            imageS3URLs={imageS3URLs}
            thumbURLs={thumbURLs}
            canEdit={canEditEvent}
            canDelete={canDeleteEvent}
            hasDescription={hasDescription}
            timelineEventMediaURLCallback={(url: string, s3URL: string) => {
              timelineEventMediaURLCallback(url, s3URL, evnt.index);
            }}
            deleteTimelineEventCallback={() =>
              deleteTimelineEventCallback(index)
            }
            showDates={showDates}
            index={evnt.index}
            key={`timeline-${index}`}
          />
        ))}
        <VerticalTimelineElement
          iconStyle={{ background: "white", color: "#fff" }}
          icon={<PlayCircleIcon sx={{ color: "black" }} />}
        />
      </VerticalTimeline>
      <TimelineModal
        isOpen={open}
        closeCallback={() => setOpen(false)}
        submitCallback={addTimelineEvent}
        imageURLs={imageURLs}
        imageS3URLs={imageS3URLs}
        thumbURLs={thumbURLs}
      />
    </>
  );
});
