import { Dialog, DialogContent } from "@mui/material";

export const Modal = (props: any) => {
  const closeCallback: any = props.closeCallback;
  const isOpen: boolean = props.isOpen;
  const children: any = props.children;

  return (
    <Dialog open={isOpen} onClose={closeCallback} maxWidth="md">
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};
