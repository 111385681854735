
import maplibregl, { NavigationControl } from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import { MAPTILER_KEY } from "../../config/config";
import { MapLibreGLHeatMaps } from "./MapLibreGLHeatMaps";
import { MapLibreGLLines } from "./MapLibreGLLines";
import { MapLibreGLMarker } from "./MapLibreGLMarker";
import { MapLibreGLPoints } from "./MapLibreGLPoints";
import { MapLibreGLTopography } from "./MapLibreGLTopography";
import { MapLibreGLUtils } from "./MapLibreGLUtils";

export class MapLibreGL {
  private map: any = null;
  private styleIsLoaded: boolean = false;
  private isLoaded: boolean = false;
  private sourceNames: string[] = [];
  private mapStyles: any = {
    demotiles: "https://demotiles.maplibre.org/style.json",
    positron: "https://basemaps.cartocdn.com/gl/positron-gl-style/style.json",
    voyager: "https://basemaps.cartocdn.com/gl/voyager-gl-style/style.json",
    mapTiler: `https://api.maptiler.com/maps/basic-v2/style.json?key=${MAPTILER_KEY}`,
  }

  private markers: MapLibreGLMarker;
  private topography: MapLibreGLTopography;
  private points: MapLibreGLPoints;
  private lines: MapLibreGLLines;
  private heatMaps: MapLibreGLHeatMaps;

  static MIN_ZOOM: number = 0;
  static MAX_ZOOM: number = 22;

  constructor(containerId: string) {

      this.map = new maplibregl.Map({
        container: containerId,
        style: this.mapStyles['voyager'],
        center: [-118.2426, 34.0549],
        zoom: 4,
      });

      let nav = new NavigationControl({showCompass: true, showZoom: true, visualizePitch: true});
      this.map.addControl(nav, 'bottom-left');
    
      this.map.on("load", async () => {
        this.isLoaded = true;
      });
      this.map.on('styledata', () => {
          this.styleIsLoaded = true;
      }); 

      this.markers = new MapLibreGLMarker(this.map);
      this.topography = new MapLibreGLTopography(this.map);
      this.points = new MapLibreGLPoints(this.map);
      this.lines = new MapLibreGLLines(this.map);
      this.heatMaps = new MapLibreGLHeatMaps(this.map);
  }

  getTopography(): MapLibreGLTopography {
    return this.topography;
  }

  getMarkers(): MapLibreGLMarker {
    return this.markers;
  }

  private getPoints(): MapLibreGLPoints {
    return this.points;
  }

  private getLines(): MapLibreGLLines {
    return this.lines;
  }

  private getHeatMaps(): MapLibreGLHeatMaps {
    return this.heatMaps;
  }

  isReady(): boolean {
      return this.styleIsLoaded && this.isLoaded;
  }

  deleteSource(sourceName: string) {
    if (this.sourceNames.find((name: string) => name === sourceName)) {
      const index: number = this.sourceNames.findIndex((name: string) => name === sourceName);
      this.sourceNames.splice(index, 1);
      if (this.map.getSource(sourceName)) {
        this.map.removeSource(sourceName);
      }
    }
  }

  addSource(sourceName: string, geojsonURLOrData: any) {
    if (! this.sourceNames.find((name: string) => name === sourceName)) {
      this.sourceNames.push(sourceName);
      this.map.addSource(sourceName, {
        type: "geojson",
        data: geojsonURLOrData,
      });
    }
  }

  deletePointLayer(
    sourceName: string,
  ) {
    this.getPoints().deletePointLayer(sourceName);
  }

  addHeatLayer(
    geojsonData: any,
    sourceName: string,
  ) {
    this.addSource(sourceName, geojsonData);
    this.getHeatMaps().addHeatLayer(sourceName);
  }

  addLineLayer(sourceName: string, geojsonURL: string, color: string, width: number, addFill: boolean = false, onClickCallback: any = null) {
    this.addSource(sourceName, geojsonURL);
    this.getLines().addLineLayer(sourceName, color, width, addFill, onClickCallback);
  }

  addPointLayer(
      geojsonData: any,
      sourceName: string,
      layerName: string,
      sourceColor: string,
      minZoom: number = MapLibreGL.MIN_ZOOM,
      maxZoom: number = MapLibreGL.MAX_ZOOM,
  ) {
      this.addSource(sourceName, geojsonData);
      this.getPoints().addPointLayer(sourceName, layerName, sourceColor, minZoom, maxZoom);
  }

  toggleLayer(layerId: string, isVisible: boolean) {
      const layerName = MapLibreGLUtils.getLayerName(layerId);      
      this.map.setLayoutProperty(layerName, 'visibility', isVisible ? 'visible' : 'none');
  }
}