export class Feature {
  icon: any = undefined;
  title: any = undefined;
  description: any = undefined;
  component: any = undefined;

  constructor(icon: any, title: any, description: any, component: any) {
    this.icon = icon;
    this.title = title;
    this.description = description;
    this.component = component;
  }
}
