import { Helmet } from "react-helmet";
import Link from "./Link";
import Metadata from "./Metadata";

export const Head = () => {
  return (
    <>
      <Helmet>
        <Metadata />
        <Link />
        <title>Azulejo Technologies</title>
      </Helmet>
    </>
  );
};
