import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { IconButton } from "@mui/material";

export const TrashButton = (props: any) => {
  const deleteCallback: any = props.deleteCallback;
  const sx: any = props.sx;

  return (
    <IconButton
      style={{
        position: "absolute",
        color: "inherit",
        opacity: "80%",
        fontSize: "sm",
        ...sx,
      }}
      onClick={deleteCallback}
    >
      <DeleteForeverIcon />
    </IconButton>
  );
};
