import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import { MapLibreGLPopup } from "./MapLibreGLPopup";

const compare = (a: number, b: number) => {
    return Math.abs(a - b) < Number.EPSILON;
}

export class MapLibreGLMarker {

    private markers: maplibregl.Marker[] = [];
    private map: maplibregl.Map;

    constructor(map: maplibregl.Map) {
        this.map = map;
    }

    getMarkers(): maplibregl.Marker[] {
        return this.markers;
    }

    createDeleteMarkerCallback = (lng: number, lat: number, onDelete: any) => () => {
        const index: number = this.markers.findIndex((marker: maplibregl.Marker) => {
            return compare(marker.getLngLat().lat, lat) && compare(marker.getLngLat().lng, lng)
        });

        if (index !== -1) {
            this.markers[index].remove();
            this.markers.splice(index, 1);
        }

        onDelete();
    };

    addMarker(lng: number, lat: number, address: string, onDelete: any) {
        const marker: maplibregl.Marker = this.createCustomSVGMarker();
        const popup: maplibregl.Popup = MapLibreGLPopup.createPinMarkerPopup(35, address, this.createDeleteMarkerCallback(lng, lat, onDelete));

        marker.setLngLat([lng, lat]).setPopup(popup).addTo(this.map);

        this.markers.push(marker);
        this.goToMarker(marker.getLngLat());
    }

    private goToMarker(markerLatLng: any) {
        this.map.flyTo({
          center: markerLatLng,
          zoom: 10,
          speed: 1.5,
          curve: 1, // Optional: Control the curvature of the animation
          essential: true // This ensures animation works even for users with prefers-reduced-motion
        });
    }

    private markerSVG(color: string = "#000000", scale: number = 1) {
        const width: number = 12.5 * scale;
        const height: number = 20 * scale;
      
        return `
      <svg xmlns="http://www.w3.org/2000/svg" height="${height}" width="${width}" viewBox="0 0 320 512">
      <!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
      <path fill="${color}" d="M16 144a144 144 0 1 1 288 0A144 144 0 1 1 16 144zM160 80c8.8 0 16-7.2 16-16s-7.2-16-16-16c-53 0-96 43-96 96c0 8.8 7.2 16 16 16s16-7.2 16-16c0-35.3 28.7-64 64-64zM128 480l0-162.9c10.4 1.9 21.1 2.9 32 2.9s21.6-1 32-2.9L192 480c0 17.7-14.3 32-32 32s-32-14.3-32-32z"/>
      </svg>`;
    }

    private createCustomSVGMarker() {
        const el = document.createElement('div');
        el.innerHTML = this.markerSVG('red', 3);
  
        const marker = new maplibregl.Marker({element: el});
        marker.getElement().style.cursor = "pointer";

        return marker;
    }
  
    private createCustomPNGMarker(fileName: string, width: string, height: string) {
        const el = document.createElement('div');
  
        el.className = 'marker';
        el.style.backgroundImage = `url(${process.env.PUBLIC_URL}/${fileName})`;
        el.style.backgroundSize = 'cover';
        el.style.width = width;
        el.style.height = height;
  
        return new maplibregl.Marker({element: el});
    }
}