import { IconButton } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useTheme } from "@mui/system";

export const CloseButton = (props: any) => {
  const closeCallback: any = props.closeCallback;
  const theme = useTheme();

  return (
    <IconButton
      onClick={closeCallback}
      style={{
        position: "absolute",
        right: 16,
        top: 16,
        color: theme.palette.error.light,
      }}
    >
      <CloseRoundedIcon />
    </IconButton>
  );
};
