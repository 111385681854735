import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export class AccordionItem {
  summary: string;
  detail: any;

  constructor(summary: string, detail: any) {
    this.summary = summary;
    this.detail = detail;
  }
}

export const AccordionList = (props: any) => {
  const items: AccordionItem[] = props.items;

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      {items.map((item: AccordionItem, index: number) => (
        <Accordion
          expanded={expanded === item.summary}
          onChange={handleChange(item.summary)}
          key={`accordion-${index}`}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${item.summary}d-content`}
            id={`${item.summary}d-header`}
          >
            <Typography component="h3" variant="subtitle2">
              {item.summary}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: "100%", md: "70%" } }}
            >
              {item.detail}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};
