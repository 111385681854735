import { PaletteMode } from "@mui/material";
import { makeAutoObservable } from 'mobx';

class ModeModel {
    mode: PaletteMode = "dark";
    showCustomTheme: boolean = true;

  constructor() {
    makeAutoObservable(this);
  }

  setMode = (mode: any): void => {
    this.mode = mode;
  };

  setShowCustomTheme = (showCustomTheme: boolean): void => {
    this.showCustomTheme = showCustomTheme;
  }

  callbackSetMode = (mode: any) => () => {
    this.setMode(mode);
  };

  callbackSetShowCustomTheme = (showCustomTheme: boolean) => () => {
    this.setShowCustomTheme(showCustomTheme);
  }

  toggleMode = () => {
    this.mode === "dark" ? this.setMode("light") : this.setMode("dark");
  };

  toggleShowCustomTheme = () => {
    this.setShowCustomTheme(!this.showCustomTheme);
  };
}

export const modeModel = new ModeModel();