
export class Dates {
    static getYMD() {
        const today: Date = new Date();
        const formattedDate = today.getFullYear() + '-' +
                            String(today.getMonth() + 1).padStart(2, '0') + '-' +
                            String(today.getDate()).padStart(2, '0');
        return formattedDate;
    }

    static isValidDate(input: any): boolean {
        if (
          typeof input === "string" ||
          typeof input === "number" ||
          input instanceof Date
        ) {
          const date = new Date(input);
          if (!isNaN(date.getTime())) {
            return true;
          }
        }
        return false;
    }
};