import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

import { AuthPaths } from "../auth/AuthPaths";
import { PricingCardStyles } from "../styles/PricingCardStyles";
import { CONTACT_EMAIL } from "../../config/config";

export const PricingCard = (props: any) => {
  const tier = props.tier;
  const appName: string = props.appName;

  const loginURL: string = AuthPaths.loginURL(appName);

  return (
    <Grid
      item
      key={tier.title}
      xs={12}
      sm={tier.title === "Enterprise" ? 12 : 6}
      md={4}
    >
      <Card
        sx={[
          {
            p: 2,
            display: "flex",
            flexDirection: "column",
            gap: 4,
            height: "400px",
          },
          tier.title === "Professional" && PricingCardStyles,
        ]}
      >
        <CardContent sx={{ flexGrow: 1 }}>
          <Box
            sx={[
              {
                mb: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 2,
              },
              tier.title === "Professional"
                ? { color: "grey.100" }
                : { color: "" },
            ]}
          >
            <Typography component="h3" variant="h6">
              {tier.title}
            </Typography>
            {tier.title === "Professional" && (
              <Chip
                icon={<AutoAwesomeIcon />}
                label={tier.subheader}
                size="small"
                sx={{
                  borderColor: "hsla(220, 60%, 99%, 0.3)",
                  backgroundColor: "hsla(220, 60%, 99%, 0.1)",
                  "& .MuiChip-label": {
                    color: "hsl(0, 0%, 100%)",
                  },
                  "& .MuiChip-icon": {
                    color: "primary.light",
                  },
                }}
              />
            )}
          </Box>
          <Box
            sx={[
              {
                display: "flex",
                alignItems: "baseline",
              },
              tier.title === "Professional"
                ? { color: "grey.50" }
                : { color: null },
            ]}
          >
            {tier.title === "DONT_SHOW" && (
              <>
                <Typography component="h3" variant="h2">
                  ${tier.price}
                </Typography>
                <Typography component="h3" variant="h6">
                  &nbsp; per month
                </Typography>
              </>
            )}
          </Box>
          <Divider sx={{ my: 2, opacity: 0.8, borderColor: "divider" }} />
          {tier.description.map((line: any) => (
            <Box
              key={line}
              sx={{
                py: 1,
                display: "flex",
                gap: 1.5,
                alignItems: "center",
              }}
            >
              <CheckCircleRoundedIcon
                sx={[
                  {
                    width: 20,
                  },
                  tier.title === "Professional"
                    ? { color: "primary.light" }
                    : { color: "primary.main" },
                ]}
              />
              <Typography
                variant="subtitle2"
                component={"span"}
                sx={[
                  tier.title === "Professional"
                    ? { color: "grey.50" }
                    : { color: null },
                ]}
              >
                {line}
              </Typography>
            </Box>
          ))}
        </CardContent>
        <CardActions>
          <Button
            fullWidth
            variant={tier.buttonVariant as "outlined" | "contained"}
            href={tier.title === "Free" ? loginURL : `mailto:${CONTACT_EMAIL}`}
          >
            {tier.buttonText}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};
