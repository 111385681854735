import {
  Auth0LoginWrapper,
  Auth0ProviderWrapper,
  AuthPaths,
} from "@azulejo/common";
import React, { Suspense } from "react";
import { APP_NAME } from "./config";

const AppPage = React.lazy(() => import(`./view/pages/AppPage`));
const AboutPage = React.lazy(() => import(`./view/pages/AboutPage`));
const LandingPage = React.lazy(() => import(`./view/pages/LandingPage`));

const LOGIN_PATH = AuthPaths.loginRoute(APP_NAME);
export const LOGOUT_PATH = AuthPaths.logoutRoute(APP_NAME);

export const routes = [
  {
    path: LOGIN_PATH,
    element: (
      <Auth0ProviderWrapper
        component={<Auth0LoginWrapper />}
        appName={APP_NAME}
      />
    ),
    children: [
      {
        path: "",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <AppPage />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: LOGOUT_PATH,
    children: [
      {
        path: "",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <LandingPage />
          </Suspense>
        ),
      },
      {
        path: `${LOGOUT_PATH}/about`,
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <AboutPage />
          </Suspense>
        ),
      },
    ],
  },
];
