import { useEffect, useRef } from "react";

import { MapLibreGL } from "../../utils/geo/MapLibreGL";
export interface IMapLayer {
  title: string;
  visible: boolean;
}

export const Map = (props: any) => {
  const width: string | number = props.width;
  const height: string | number = props.height;
  const mapLayers: IMapLayer[] = props.mapLayers;
  const onCreateMapLibreGL: (map: MapLibreGL) => void =
    props.onCreateMapLibreGL;
  const containerId: string = "map";
  const map: any = useRef(null);

  useEffect(() => {
    if (!map.current) {
      map.current = new MapLibreGL(containerId);
      onCreateMapLibreGL(map.current);
    }
  }, []);

  useEffect(() => {
    if (map.current && map.current.isReady()) {
      mapLayers.forEach((data: IMapLayer) =>
        map.current?.toggleLayer(data.title, data.visible)
      );
    }
  }, [mapLayers]);

  return <div id={containerId} style={{ width: width, height: height }} />;
};
