import { Box } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/system";
import { useState } from "react";
import { FeaturesGrid } from "./FeaturesGrid";
import { FeaturesStack } from "./FeaturesStack";

export const FeaturesPreview = (props: any) => {
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);

  const direction: string = props.direction;
  const text: string = props.text;
  const items: any = props.items;
  const sx: any = props.sx;
  const selectedFeature = items[selectedItemIndex];

  const theme: any = useTheme();

  return (
    <Container
      id="features"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h2"
        sx={{
          color: "text.primary",
          fontFamily: theme.typography.fontFamily,
        }}
      >
        {text}
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} md={direction !== "row" ? 3 : 12}>
          {/* This is the mobile React components */}
          <Box sx={{ display: { xs: "auto", sm: "none" } }}>
            <FeaturesGrid
              items={items}
              selectedItem={selectedFeature}
              selectedItemIndex={selectedItemIndex}
              clickCallback={setSelectedItemIndex}
            />
          </Box>
          {/* This is the desktop React components */}
          <Box sx={{ display: { xs: "none", sm: "flex" } }}>
            <FeaturesStack
              direction={direction}
              items={items}
              selectedItemIndex={selectedItemIndex}
              clickCallback={setSelectedItemIndex}
              sx={sx}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={direction !== "row" ? 9 : 12}>
          {/* This is the desktop React components */}
          <Box sx={{ display: { xs: "none", sm: "flex" } }}>
            {selectedFeature.component}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
