export const PublicImage = (props: any) => {
  let fileName: string = props.filename;
  let iconURL = `${process.env.PUBLIC_URL}/${fileName}`;

  return (
    <>
      <img src={iconURL} alt={fileName} {...props} />
    </>
  );
};
