import { Grid } from "@mui/material";
import { FeatureChip } from "../../chips/FeatureChip";
import { FeatureMobileCard } from "../../cards/FeatureMobileCard";

export const FeaturesGrid = (props: any) => {
  const items: any = props.items;
  const selectedItem: any = props.selectedItem;
  const selectedItemIndex: number = props.selectedItemIndex;
  const clickCallback: (index: number) => void = props.clickCallback;

  return (
    <>
      <Grid container item sx={{ gap: 1 }}>
        {items.map(({ title }: any, index: number) => (
          <FeatureChip
            key={index}
            label={title}
            onClick={() => clickCallback(index)}
            selected={selectedItemIndex === index}
          />
        ))}
      </Grid>
      <FeatureMobileCard
        title={selectedItem.title}
        component={selectedItem.component}
        description={selectedItem.description}
      />
    </>
  );
};
