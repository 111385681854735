
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import { MAPTILER_KEY } from "../../config/config";
import { MapLibreGLUtils } from "./MapLibreGLUtils";

export class MapLibreGLTopography {
    map: maplibregl.Map;

    constructor(map: maplibregl.Map) {
        this.map = map;
    }

    addTerrainLayer(sourceName: string) {
        const layerName = MapLibreGLUtils.getLayerName(sourceName);      
    
        this.map.addSource(sourceName, {
          type: 'raster-dem',
          tiles: [
              `https://api.maptiler.com/tiles/terrain-rgb-v2/{z}/{x}/{y}.webp?key=${MAPTILER_KEY}`
          ],
          tileSize: 256,
          maxzoom: 14
        });
        this.map.setTerrain({ source: sourceName, exaggeration: 1.5 });
        this.map.addLayer({
          id: layerName,
          source: sourceName,
          type: 'hillshade'
        });
      }
    
      add3DBuildingsLayer(sourceName: string) {
        const layerName = MapLibreGLUtils.getLayerName(sourceName);      
    
        this.map.addSource(sourceName, {
          url: `https://api.maptiler.com/tiles/v3/tiles.json?key=${MAPTILER_KEY}`,
          type: 'vector',
        });
    
        this.map.addLayer(
          {
              'id': layerName,
              'source': sourceName,
              'source-layer': 'building',
              'type': 'fill-extrusion',
              'minzoom': 15,
              'filter': ['!=', ['get', 'hide_3d'], true],
              'paint': {
                  'fill-extrusion-color': [
                      'interpolate',
                      ['linear'],
                      ['get', 'render_height'], 0, 'lightgray', 200, 'royalblue', 400, 'lightblue'
                  ],
                  'fill-extrusion-height': [
                      'interpolate',
                      ['linear'],
                      ['zoom'],
                      15,
                      0,
                      16,
                      ['get', 'render_height']
                  ],
                  'fill-extrusion-base': ['case',
                      ['>=', ['get', 'zoom'], 16],
                      ['get', 'render_min_height'], 0
                  ]
              }
          }
        );
      }
}