import { SERVER_HTTPS_ENDPOINT } from '../config/config';
import { IAppDTO } from './AppDTO';
import { ServerClient } from './ServerClient';

export class AppClient extends ServerClient {
  constructor(token: string = "") {
    super(token);
  }

  async getPresignedURL(dto: IAppDTO): Promise<string | void> {
    if (! dto.bucket || ! dto.key) {
        throw new Error("ERROR: bucket and key must be provided");
    }

    console.debug('app client - get - ', dto);
    const bucket = encodeURIComponent(dto.bucket).replace(/\./g, '%2E');
    const key = encodeURIComponent(dto.key).replace(/\./g, '%2E');
    const url = `${SERVER_HTTPS_ENDPOINT}/api/app/presigned-s3-url?bucket=${bucket}&key=${key}`;
    return await this.fetchCall(url, this.getOptions(url));
  }
}
