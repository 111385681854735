import { TextField } from "@mui/material";

export const MultilineText = (props: any) => {
  const inputText: string = props.text;
  const disabled: boolean =
    props.disabled !== undefined ? props.disabled : false;
  const sx: any = props.sx;

  return (
    <TextField
      disabled={disabled}
      multiline
      minRows={1}
      value={inputText}
      sx={{
        overflow: "scroll",
        width: "100%",
        textAlign: "center",
        "& .MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: "inherit",
          color: "black",
        },
        ...sx,
      }}
    />
  );
};
