import maplibregl from "maplibre-gl";
import { createRoot } from "react-dom/client";

const Component = (props: any) => {
  const onClick: any = props.onClick;
  const address: string = props.address;

  return (
    <div>
      <h3>Demand Point</h3>
      <p>{address}</p>
      <button onClick={onClick}>Delete</button>
    </div>
  );
};

export class MapLibreGLPopup {
  static createPinMarkerPopup = (
    offset: number,
    address: string,
    onClick: any
  ) => {
    const domComponent = document.createElement("div");
    const root = createRoot(domComponent);
    root.render(<Component address={address} onClick={onClick} />);

    const popup = new maplibregl.Popup({ offset: offset });
    popup.setDOMContent(domComponent);
    return popup;
  };
}
