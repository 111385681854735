export class UnAuthenticatedParamResolver {
    private static isPublicPage = (projectUUID: string | undefined) => {
        return !projectUUID;
    };

    static resolveEmail(emailParam: string | undefined, projectUUIDParam: string | undefined, appName: string) {
        return this.isPublicPage(projectUUIDParam) ? `${appName}@azulejo.io` : emailParam;
    }

    static resolveProjectUUID(projectUUIDParam: string | undefined, appName: string) {    
        return this.isPublicPage(projectUUIDParam) ? appName : projectUUIDParam;
    }
}