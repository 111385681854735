import {
  Auth0LoginWrapper,
  Auth0ProviderWrapper,
  AuthPaths,
} from "@azulejo/common";
import React, { Suspense } from "react";
import { APP_NAME } from "./config";

const LandingPage = React.lazy(() => import(`./view/pages/LandingPage`));
const ProjectsPage = React.lazy(() => import(`./view/pages/ProjectsPage`));
const AlbumPage = React.lazy(() => import(`./view/pages/AlbumPage`));
const AlbumsPage = React.lazy(() => import(`./view/pages/AlbumsPage`));
const CalendarPage = React.lazy(() => import(`./view/pages/CalendarPage`));
const AboutPage = React.lazy(() => import(`./view/pages/AboutPage`));

export const LOGOUT_PATH = AuthPaths.logoutRoute(APP_NAME);
export const LOGIN_PATH = AuthPaths.loginRoute(APP_NAME);
export const ALBUM_PATH = `${LOGIN_PATH}/album`;
export const ALBUMS_PATH = `${LOGIN_PATH}/albums`;
export const CALENDAR_PATH = `${LOGIN_PATH}/calendar`;
export const ABOUT_PATH = `${LOGIN_PATH}/about`;

export const routes = [
  {
    path: LOGIN_PATH,
    element: (
      <Auth0ProviderWrapper
        component={<Auth0LoginWrapper />}
        appName={APP_NAME}
      />
    ),
    children: [
      {
        path: "",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <ProjectsPage />
          </Suspense>
        ),
      },
      {
        path: `${ALBUMS_PATH}/:projectUUID`,
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <AlbumsPage />
          </Suspense>
        ),
      },
      {
        path: `${ALBUM_PATH}/:projectUUID/:eventUUID`,
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <AlbumPage />
          </Suspense>
        ),
      },
      {
        path: `${CALENDAR_PATH}/:projectUUID`,
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <CalendarPage />
          </Suspense>
        ),
      },
      {
        path: `${ABOUT_PATH}/:projectUUID`,
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <AboutPage />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: `${LOGOUT_PATH}/about`,
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <AboutPage />
      </Suspense>
    ),
  },
  {
    path: `${LOGOUT_PATH}/calendar`,
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <CalendarPage />
      </Suspense>
    ),
  },
  {
    path: LOGOUT_PATH,
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <LandingPage />
      </Suspense>
    ),
  },
];
