import { Auth0LoginButton } from "./Auth0LoginButton";
import { Auth0LogoutButton } from "./Auth0LogoutButton";

export const Auth0Login = (props: any) => {
  const email: string | undefined = props.email;
  const appName: string = props.appName;
  const sx: any = props.sx;
  return (
    <>
      {email ? (
        <Auth0LogoutButton appName={appName} sx={sx} />
      ) : (
        <Auth0LoginButton name="Sign in" appName={appName} sx={sx} />
      )}
    </>
  );
};
