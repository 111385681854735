import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";

export const SandwichMenuButton = (props: any) => {
  const clickCallback: any = props.clickCallback;
  const sx: any = props.sx;

  return (
    <IconButton aria-label="Menu button" onClick={clickCallback} sx={sx}>
      <MenuIcon />
    </IconButton>
  );
};
