import { Box } from "@mui/material";
import { useEffect } from "react";

export const SubstackPost = (props: any) => {
  const url: string = props.url;
  const sx: any = props.sx;

  useEffect(() => {
    // Dynamically load the Substack embed script
    const script = document.createElement("script");
    script.src = "https://substack.com/embedjs/embed.js";
    script.async = true;
    script.charset = "utf-8";
    document.body.appendChild(script);

    // // Apply styles after the script runs
    // const applyStyles = () => {
    //   const embed: any = document.querySelector(".substack-post-embed");
    //   if (embed) {
    //     embed.style.bgcolor = "#07404b";
    //     embed.style.background = "none";
    //     embed.style.backgroundColor = "#07404b";
    //   }
    // };

    // setTimeout(applyStyles, 300); // Wait for the script to modify the DOM

    // Cleanup: Remove the script tag if the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Box sx={sx}>
      <div className="substack-post-embed">
        <a data-post-link href={url} />
      </div>
    </Box>
  );
};
