import maplibregl from "maplibre-gl";
import { MapLibreGLUtils } from "./MapLibreGLUtils";

export class MapLibreGLLines {
  private map: maplibregl.Map;

  constructor(map: maplibregl.Map) {
      this.map = map;
  }

  addLineLayer(sourceName: string, color: string, width: number, addFill: boolean = false, onClickCallback: any = null) {

    const layerName: string = MapLibreGLUtils.getLayerName(sourceName);
    const fillLayerName: string = MapLibreGLUtils.getFillLayerName(sourceName);

    this.map.addLayer({
        id: layerName,
        type: 'line',
        source: sourceName,
        paint: {
            'line-color': color,
            'line-width': width,
        }
    });

    if (addFill) {
      this.map.addLayer({
        id: fillLayerName,
        type: 'fill',
        source: sourceName,
        paint: {
            'fill-color': color,
            'fill-opacity': 0.0
        }
      });

      if (onClickCallback) {
        this.map.on('click', fillLayerName, onClickCallback);
      }
    }
  }
}