import { AuthPaths } from "@azulejo/common";
import React, { Suspense } from "react";

const LandingPage = React.lazy(() => import(`./view/pages/LandingPage`));

const LOGOUT_PATH = AuthPaths.logoutRoute();

export const routes = [
  {
    path: LOGOUT_PATH,
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <LandingPage />
      </Suspense>
    ),
  },
];
