import Grid from "@mui/material/Grid";

import { ImageGridItem } from "./ImageGridItem";

export const ImageGrid = (props: any) => {
  const imageURLs: string[] = props.imageURLs;
  const imageS3URLs: string[] = props.imageS3URLs;
  const thumbURLs: string[] = props.thumbURLs;
  const deleteCallback: any = props.deleteCallback;
  const clickCallback: any = props.clickCallback;
  const children: any = props.children;
  const openImages: boolean =
    props.openImages !== undefined ? props.openImages : true;
  const sx: any = props.sx;
  const itemSx: any = props.itemsx;
  const imageSx: any = props.imageSx;

  return (
    <Grid container spacing={0} sx={sx}>
      {children}
      {thumbURLs?.map((url: string, index: number) => {
        const clickCallbackWrapper: () => void = () => {
          clickCallback(url, imageS3URLs[index]);
        };

        const deleteCallbackWrapper: () => void = () => {
          deleteCallback(index);
        };

        return (
          <ImageGridItem
            thumbURL={url}
            imageURL={imageURLs[index]}
            deleteCallback={
              deleteCallback ? deleteCallbackWrapper : deleteCallback
            }
            clickCallback={clickCallback ? clickCallbackWrapper : clickCallback}
            openImages={openImages}
            key={`image-${index}`}
            sx={itemSx}
            imageSx={imageSx}
          />
        );
      })}
    </Grid>
  );
};
