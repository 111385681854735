export const RemoteImage = (props: any) => {
  let iconURL: string = props.iconurl;

  let alt: string = iconURL.substring(iconURL.lastIndexOf("/") + 1);

  return (
    <>
      <img src={iconURL} alt={alt} {...props} />
    </>
  );
};
