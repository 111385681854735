import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

export const EditButton = (props: any) => {
  const clickCallback: any = props.clickCallback;
  const sx: any = props.sx;

  return (
    <IconButton
      style={{
        position: "absolute",
        color: "inherit",
        opacity: "80%",
        fontSize: "sm",
        ...sx,
      }}
      onClick={clickCallback}
    >
      <EditIcon />
    </IconButton>
  );
};
