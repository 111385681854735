import maplibregl from 'maplibre-gl';

export class Geometry {
    static distanceMetric = (lng1: number, lat1: number, lng2: number, lat2: number) => {
        const point1 = new maplibregl.LngLat(lng1, lat1);
        const point2 = new maplibregl.LngLat(lng2, lat2);
        
        return point1.distanceTo(point2);
    }

    static distanceImperial = (lng1: number, lat1: number, lng2: number, lat2: number) => {        
        const distanceInMeters = Geometry.distanceMetric(lng1, lat1, lng2, lat2);
        const distanceInMiles = distanceInMeters / 1609.344; // 1 mile = 1609.344 meters
        return distanceInMiles;
    };

    static filterFeaturesWithinRadius(geojson: any, refLng: number, refLat: number, maxDist: number) {    
        return geojson.features.filter((feature: any) => {
            const [lng, lat] = feature.geometry.coordinates;
            const distance: number = Geometry.distanceImperial(refLng, refLat, lng, lat);
            return distance <= maxDist;
        });
    }
}