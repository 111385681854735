import { TextField } from "@mui/material";
import { useState } from "react";

export const MultilineTextField = (props: any) => {
  const [inputText, setInputText] = useState<string>(props.text);
  const [sourceText, setSourceText] = useState<string>(inputText);
  const callback: (newText: string) => any = props.callback;
  const placeholder: string = props.placeholder
    ? props.placeholder
    : "Please enter text";
  const sx: any = props.sx;
  const disabled: boolean =
    props.disabled !== undefined ? props.disabled : false;

  return (
    <TextField
      multiline
      minRows={1}
      disabled={disabled}
      onChange={(event: any) => {
        setInputText(event.target.value);
      }}
      onBlur={() => {
        if (inputText !== sourceText) {
          callback(inputText);
        }
      }}
      onFocus={(event: any) => {
        setSourceText(event.target.value);
      }}
      value={inputText}
      placeholder={placeholder}
      sx={{
        "& .MuiInputBase-input": {
          textAlign: "center",
        },
        "& .MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: "inherit",
          color: "black",
        },
        width: "100%",
        textAlign: "center",
        ...sx,
      }}
    />
  );
};
